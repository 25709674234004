<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Produto"
          class="px-5 py-3"
        >
          <v-subheader class="display-1 mt-3">
            Informações Principais
          </v-subheader>

          <v-form class="pt-1" @submit.prevent="postProduct">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Nome"
            >
              <v-text-field
                label="Nome *"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="name"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Marca"
            >
              <v-text-field
                label="Marca *"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="brand"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Localização"
            >

              <v-text-field
                label="Localização *"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="location"
              />

            </validation-provider>

              <v-text-field
                label="Quantidade"
                type="number"
                outlined
                class="ml-4"
                v-model="qty"
              />

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Preço"
            >
              <v-text-field
                label="Preço *"
                type="number"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="price"
              />
            </validation-provider>

            <v-subheader class="display-1 mt-3">
              Informações Adicionais
            </v-subheader>

              <v-text-field
                label="Aviso de Estoque Baixo"
                type="number"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="low_stock"
              />

              <v-textarea
                name="input-7-1"
                label="Observações"
                value=""
                hint=""
                class="ml-4"
                v-model="ps"
              />

            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import StocksService from '@/services/StocksService'

  export default {
    name: 'EditProduct',

    components: { 

    },

    data: () => ({
      name: "",
      location: "",
      qty: "",
      low_stock: "",
      price: "",
      brand: "",
      ps: "",
    }),

    mounted () {
      if(this.$route.params.id){
        this.getProduct(this.$route.params.id)
      }
    },

    computed: {
      
    },

    methods: {

      postProduct() {
        let data = []

        data['name']      =  this.name
        data['location']  =  this.location
        data['qty']       =  this.qty
        data['low_stock'] =  this.low_stock
        data['price']     =  this.price
        data['brand']     =  this.brand
        data['ps']        =  this.ps

        StocksService.postProduct(data,this.$route.params.id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Error"){
            throw 500
          }
          this.$toast.success('Produto Salvo Sucesso.', '',{position:'topRight'})
          this.$router.push({ name: 'Estoque' })
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },

      getProduct(id) {
        StocksService.getProduct(id)
        .then(response => {
          this.name = response.data.product.name,
          this.location = response.data.product.location,
          this.qty = response.data.product.qty,
          this.low_stock = response.data.product.low_stock,
          this.price = response.data.product.price,
          this.brand = response.data.product.brand,
          this.ps = response.data.product.ps
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar consulta.', '',{position:'topRight'})
        })
      },
    },
  }
</script>