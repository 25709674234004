var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"success","inline":"","title":"Produto"}},[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" Informações Principais ")]),_c('v-form',{staticClass:"pt-1",on:{"submit":function($event){$event.preventDefault();return _vm.postProduct($event)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Nome *","outlined":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Marca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Marca *","outlined":"","error-messages":errors},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Localização"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Localização *","outlined":"","error-messages":errors},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})]}}])}),_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Quantidade","type":"number","outlined":""},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}}),_c('validation-provider',{attrs:{"rules":"required","name":"Preço"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Preço *","type":"number","outlined":"","error-messages":errors},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}])}),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" Informações Adicionais ")]),_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Aviso de Estoque Baixo","type":"number","outlined":"","error-messages":_vm.errors},model:{value:(_vm.low_stock),callback:function ($$v) {_vm.low_stock=$$v},expression:"low_stock"}}),_c('v-textarea',{staticClass:"ml-4",attrs:{"name":"input-7-1","label":"Observações","value":"","hint":""},model:{value:(_vm.ps),callback:function ($$v) {_vm.ps=$$v},expression:"ps"}}),_c('v-card-actions',{staticClass:"pl-0"},[_c('v-btn',{attrs:{"color":"success","min-width":"100","type":"submit"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }